import PopupStateMachine, { STATE_CLOSED, STATE_READY, STATE_PREPARING, STATE_PENDING_TC, STATE_PROCESSED, STATE_SUBMITTED, STATE_NOTIFICATION } from './StateMachine';

export default class {
  constructor(overlay, threadId) {
    this.overlay = overlay;
    this.stateMachine = new PopupStateMachine(threadId);
    this.stateMachine.subscribe(this.onStateChange.bind(this));
  }

  setReplyTo(replyTo) {
    this.stateMachine.setReplyTo(replyTo);
  }

  show() {
    this.stateMachine.reset();
  }

  hide() {
    this.overlay.dataset.isVisible = false;
  }

  onStateChange(newState) {
    switch (newState.state) {
      case STATE_READY:
        this.overlay.innerHTML = '';
        this.overlay.append(newState.content);
        this.overlay.dataset.isVisible = true;
        /*const modalBg = document.getElementsByClassName('modalBackground')[0];
        modalBg.classList.add('active');
        newState.content.subToCloseFunction(modalBg);*/
        break;
      case STATE_CLOSED:
        this.overlay.innerHTML = '';
        this.overlay.dataset.isVisible = false;
        break;
      case STATE_PREPARING:
      case STATE_PENDING_TC:
      case STATE_PROCESSED:
      case STATE_SUBMITTED:
        this.overlay.querySelector('.ziggeo-recorder-container')
          .append(newState.content);
        break;
      case STATE_NOTIFICATION:
        this.overlay.querySelector('.ziggeo-recorder-container')
        .append(newState.content);
        this.overlay.querySelector('#notification-email').focus();
      break;
        break;
    }
  }
}