const ZIGGEO_SUBMIT_BTN_SELECTOR = '[data-selector="player-submit-button"]';
const SAFARI_SUBMIT_BTN_SELECTOR = '[data-action="submit"]';

export default class {

  activateOn(container, callback) {
    this.container = container;
    const handler = this.getDynamicInvoker(callback);
    // remove the listener if it already attached to the element
    container.removeEventListener('click', handler);
    // listener is attached to the submit button's parent element
    // in CAPTURING phase, sog it can be activated before the submit button
    // has been clicked. Otherwise the video will be submitted before the
    // the TC wall gets shown.
    container.addEventListener('click', handler, true);
  }

  getDynamicInvoker(callback) {
    if (!this.handler) {
      this.handler = this.createDynamicInvoker(callback);
    }
    return this.handler;
  }

  localStorageTest(){
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

  createDynamicInvoker(callback) {
    return (ev) => {
      if (!this.container.contains(ev.target)) {
        return;
      }

      if (!ev.target.matches(ZIGGEO_SUBMIT_BTN_SELECTOR) && !ev.target.matches(SAFARI_SUBMIT_BTN_SELECTOR)) {
        return;
      }

      if (!this.localStorageTest() || !localStorage.getItem('is_vialog_tc_accepted')) {
        ev.stopImmediatePropagation();
        callback();
      }
    }
  }
}