import VialogPlugin from './components/Plugin';

const PLUGIN_ELEMENT_CLASS_ISOLATE = 'vialog-plugin';
const FRONTEND_BASE_URL = process.env.FRONTEND_BASE_URL;

if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
    // Firefox 38+ seems having support of enumerateDevicesx
    navigator.enumerateDevices = function(callback) {
        navigator.mediaDevices.enumerateDevices().then(callback);
    };
}

var MediaDevices = [];
var isHTTPs = location.protocol === 'https:';
var canEnumerate = false;

if (typeof MediaStreamTrack !== 'undefined' && 'getSources' in MediaStreamTrack) {
    canEnumerate = true;
} else if (navigator.mediaDevices && !!navigator.mediaDevices.enumerateDevices) {
    canEnumerate = true;
}

var hasMicrophone = false;
var hasSpeakers = false;
var hasWebcam = false;

var isMicrophoneAlreadyCaptured = false;
var isWebcamAlreadyCaptured = false;

function checkDeviceSupport(callback) {
    if (!canEnumerate) {
        return;
    }

    if (!navigator.enumerateDevices && window.MediaStreamTrack && window.MediaStreamTrack.getSources) {
        navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(window.MediaStreamTrack);
    }

    if (!navigator.enumerateDevices && navigator.enumerateDevices) {
        navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
    }

    if (!navigator.enumerateDevices) {
        if (callback) {
            callback();
        }
        return;
    }

    MediaDevices = [];
    navigator.enumerateDevices(function(devices) {
        devices.forEach(function(_device) {
            var device = {};
            for (var d in _device) {
                device[d] = _device[d];
            }

            if (device.kind === 'audio') {
                device.kind = 'audioinput';
            }

            if (device.kind === 'video') {
                device.kind = 'videoinput';
            }

            var skip;
            MediaDevices.forEach(function(d) {
                if (d.id === device.id && d.kind === device.kind) {
                    skip = true;
                }
            });

            if (skip) {
                return;
            }

            if (!device.deviceId) {
                device.deviceId = device.id;
            }

            if (!device.id) {
                device.id = device.deviceId;
            }

            if (!device.label) {
                device.label = 'Please invoke getUserMedia once.';
                if (!isHTTPs) {
                    device.label = 'HTTPs is required to get label of this ' + device.kind + ' device.';
                }
            } else {
                if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
                    isWebcamAlreadyCaptured = true;
                }

                if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
                    isMicrophoneAlreadyCaptured = true;
                }
            }

            if (device.kind === 'audioinput') {
                hasMicrophone = true;
            }

            if (device.kind === 'audiooutput') {
                hasSpeakers = true;
            }

            if (device.kind === 'videoinput') {
                hasWebcam = true;
            }
            MediaDevices.push(device);
        });

        if (callback) {
            callback();
        }
    });
}

function isFacebookApp() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
}

function isAndroid() {
  var ua = navigator.userAgent || navigator.vendor || window.opera;
  return (ua.indexOf("Android") > -1);
}

const isRecordingPermitted = {
  hasWebcam,
  hasMicrophone,
  isMicrophoneAlreadyCaptured,
  isWebcamAlreadyCaptured,
  isFacebookApp: isFacebookApp(),
  isAndroid: isAndroid(),
}

function inIframe () {
  try {
      return window.self !== window.top && document.getElementsByClassName("vialog-plugin-wrap").length >= 1;
  } catch (e) {
    console.log(e);
      return true;
  }
}

function parseQueryParams() {
  const queryString = window.location.search.substring(1);
  return queryString
    .split('&')
    .map(query => query.split('='))
    .reduce((parsed, queryParam) => {
      parsed[queryParam[0]] = queryParam[1];
      return parsed;
    }, {});
}

const resizeFrame = (embedIframe, headingHeight = 32) => {
  const computedStyle = getComputedStyle(embedIframe.parentNode);
  const width = embedIframe.parentNode.clientWidth - parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
  const originalHeight = width > 768 ? 486 : 376;
  const height = Math.max(originalHeight, width);
  embedIframe.width = width;
  embedIframe.height = height;
  if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    embedIframe.style.setProperty('height', height + 1 + (headingHeight - 32) + 'px', 'important');
  } else {
    embedIframe.style.setProperty('height', originalHeight + (headingHeight - 32) + 'px', 'important');
  }
  embedIframe.contentWindow.postMessage(JSON.stringify({width}), '*');
};

const eventMethod = window.addEventListener
			? "addEventListener"
			: "attachEvent";
const eventer = window[eventMethod];
const messageEvent = eventMethod === "attachEvent"
		? "onmessage"
		: "message";

eventer(messageEvent, (e) => {
	let message = null;
  try {
    message = JSON.parse(e.data || e.message);
  } catch {}
  if (message && message.headingHeight) {
    const embedIframe = document.querySelector(`.vialog-frame[data-id="${message.id}"]`)
    resizeFrame(embedIframe, message.headingHeight);
  }
});

const postHeadingHeight = () => {
  const heading = document.querySelector('h1');
  if (heading) {
    const headingHeight = heading.getBoundingClientRect().height;
    const params = parseQueryParams();
    const id = params.id;
    parent.postMessage(JSON.stringify({headingHeight, id}), "*");
  }
};

if (inIframe()) {
  //console.log('In IFrame');
  eventer(messageEvent, (e) => {
    let message = null;
    try {
      message = JSON.parse(e.data || e.message);
      document.querySelector('.vialog-plugin-local').style.setProperty('width', message.width + 'px');
    } catch {}
  });
  const params = parseQueryParams();
  //console.log(params);
  document.querySelector('.vialog-plugin-wrap').innerHTML = `<div class="vialog-plugin-local" data-title="${decodeURI(params.title)}" data-partner-id="${decodeURI(params.partnerId)}" data-topic-id="${decodeURI(params.topicId)}" data-answer-label="${decodeURI(params.answerLabel)}" data-question-label="${decodeURI(params.questionLabel)}" data-reply-card="${decodeURI(params.replyCard)}" data-action="${decodeURI(params.action)}"></div>`;
  window.addEventListener('resize', () => {
    postHeadingHeight();
  });
  const plugin = new VialogPlugin(isRecordingPermitted);
  plugin.init().then(postHeadingHeight);
} else {
  //console.log('Not in IFrame');
  const isolatePlugins = document.querySelectorAll('.' + PLUGIN_ELEMENT_CLASS_ISOLATE);
  let id = 0;
  [...isolatePlugins].forEach(plugin => {
    const isolatedFrame = document.createElement('iframe');
    const {
      title,
      partnerId,
      topicId,
      replyCard = 'Share knowledge and find answers!',
      questionLabel = 'Question',
      answerLabel = 'Answer',
      action = 'Reply',
    } = plugin.dataset;
    isolatedFrame.classList.add('vialog-frame');
    isolatedFrame.setAttribute('allow', 'speakers *; autoplay *; fullscreen *; microphone *; camera *; usermedia *; display-capture *;');
    isolatedFrame.setAttribute('allowspeakers', 'allowspeakers');
    isolatedFrame.setAttribute('allowautoplay', 'allowautoplay');
    isolatedFrame.setAttribute('allowfullscreen', 'allowfullscreen');
    isolatedFrame.setAttribute('allowmicrophone', 'allowmicrophone');
    isolatedFrame.setAttribute('allowmicrophone', 'allowmicrophone');
    isolatedFrame.setAttribute('allowcamera', 'allowcamera');
    isolatedFrame.setAttribute('allowusermedia', 'allowusermedia');
    isolatedFrame.setAttribute('src', `${FRONTEND_BASE_URL}/embed.html?title=${encodeURI(title)}&partnerId=${encodeURI(partnerId)}&topicId=${encodeURI(topicId)}&replyCard=${encodeURI(replyCard)}&answerLabel=${encodeURI(answerLabel)}&questionLabel=${encodeURI(questionLabel)}&id=${id}&action=${encodeURI(action)}`);
    isolatedFrame.setAttribute('data-id', id);
    isolatedFrame.setAttribute('id', 'vialogIFrame')
    id++;
    isolatedFrame.onload = () => resizeFrame(isolatedFrame);
    plugin.appendChild(isolatedFrame);
  });
  const embedIframes = document.querySelectorAll('.vialog-frame')
  const resizeAllFrames = () =>
    [...embedIframes].forEach((frame) => resizeFrame(frame));
  resizeAllFrames();
  window.addEventListener('resize', () => {
    resizeAllFrames()
  });
  const plugin = new VialogPlugin(isRecordingPermitted);
  plugin.init();
}
