import Carousel from './Carousel';

const ZIGGEO_CSS_URL = '//assets-cdn.ziggeo.com/v2-r35/ziggeo.css';
const ZIGGEO_JS_URL = '//assets-cdn.ziggeo.com/v2-r35/ziggeo.js';
const VIALOG_CSS_URL = `${process.env.FRONTEND_BASE_URL}/vialog.css?v=20201012`;
const ZIGGEO_TOKEN = process.env.ZIGGEO_TOKEN;

const F_AWESOME_CSS_URL = 'https://use.fontawesome.com/releases/v5.11.2/css/all.css';

const PLUGIN_ELEMENT_CLASS = 'vialog-plugin-local';

export default class {
  constructor(isRecordingPermitted) {
    this.isRecordingPermitted = isRecordingPermitted;
  }

  init() {
    return this.loadDependencies()
      .then(_ => {
        const ziggeoApp = new ZiggeoApi.V2.Application({
          token: ZIGGEO_TOKEN,
          debugger: process.env.NODE_ENV !== 'production',
        });
      })
      .then(_ => {
        ZiggeoApi.V2.Locale.setLocale('en');
        ZiggeoApi.V2.Locale.mediaLocale.register({
          'ba-videorecorder-controlbar.record': 'start',
          'ba-videorecorder-controlbar.stop': 'stop',
          'ba-videoplayer-playbutton.submit-video': '&#x2714;&#xfe0e; Happy!',
          'ba-videoplayer-playbutton.rerecord': 'Redo',
          'ba-videorecorder.uploading': 'Converting',
          'ba-videorecorder.verifying': 'Converting',
          'ba-videorecorder-chooser.record-video': 'Start',
          'ba-videorecorder.attach-error': 'Please use another browser or your mobile device to record your video.',
          'ba-videorecorder.access-forbidden': `
            Please give permission.
              1) In the URL field, click the icon (camera or lock icon).
              2) Change permission setting to 'Allow' for both Camera and Microphone. Your changes will automatically save.
              3) You may need to refresh the page.
          `
        }, 5);

        ZiggeoApi.V2.Locale.mainLocale.register({
          'ziggeoplayer.video-processing': 'Preparing preview...',
        },5);
      })
      .then(_ => this.initCarousels())
      .catch(err => console.log('cannot initialize plugin: ', err));
  }

  loadDependencies() {
    return new Promise((resolve, reject) => {
      const head = document.querySelector('head');
      const linkVialog = document.createElement('link');
      linkVialog.rel = 'stylesheet';
      linkVialog.type = 'text/css';
      linkVialog.href = VIALOG_CSS_URL;
      linkVialog.media = 'all';
      head.prepend(linkVialog);

      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = ZIGGEO_CSS_URL;
      link.media = 'all';
      head.prepend(link);

      const linkFont = document.createElement('link');
      linkFont.rel = 'stylesheet';
      linkFont.type = 'text/css';
      linkFont.href = 'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap';
      linkFont.media = 'all';
      head.prepend(linkFont);

      const script = document.createElement('script');
      script.src = ZIGGEO_JS_URL;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);

      const fontAwesomeCSS = document.createElement('link');
      fontAwesomeCSS.href = F_AWESOME_CSS_URL;
      fontAwesomeCSS.rel = 'stylesheet';
      document.head.appendChild(fontAwesomeCSS);

      const script2 = document.createElement('script');
      script2.setAttribute('type', 'text/javascript')
      script2.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
      heap.load("2113536554");`;
      document.head.appendChild(script2);
    });
  }

  initCarousels() {
    const carousels = document.querySelectorAll(`.${PLUGIN_ELEMENT_CLASS}`);
    return Promise.all([...carousels]
      .map(carouselEl => new Carousel(carouselEl, this.isRecordingPermitted))
      .map(carousel => carousel.init()));
  }
}
