import { fetchTemplate } from '../utils';
import Popup from './Popup';

const POPUP_ELEMENT_CLASS = 'vialog-popup';
const ADMIN_URL = process.env.ADMIN_API_BASE;
const MOD_API_BASE = process.env.MOD_API_BASE;

let isScrolling = false;
let lastReplayer = null;

const scrollLeft = (event) => {
  const width = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--recorder-width').split('px')[0].trim())+21;
  const domElem = event.target.parentNode;
  const to = domElem.scrollLeft-width > 0 ? domElem.scrollLeft-width : 0;
  scrollTo(domElem, to, 250);
}

const scrollRight = (event) => {
  const width = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--recorder-width').split('px')[0].trim())+21;
  const domElem = event.target.parentNode;
  const to = domElem.scrollLeft+width < domElem.scrollWidth ? domElem.scrollLeft+width : domElem.scrollWidth;
  scrollTo(domElem, to, 250);
}

function scrollTo(element, to, duration) {
  if (isScrolling){return;}
  isScrolling = true;
  var start = element.scrollLeft,
      change = to - start,
      currentTime = 0,
      increment = 20;

  var animateScroll = function(){
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if(currentTime < duration) {
          setTimeout(animateScroll, increment);
      } else {
        isScrolling = false;
      }
  };
  animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

const carouselList = [];
const updateCarouselList = () => {
  carouselList.forEach(item => {
    item.dispatchEvent(new CustomEvent('scroll'));
  });
}

function registerResizeObserver(domElem) {
  function sendEvent(){
    domElem.dispatchEvent(new CustomEvent('scroll'));
  }
  new ResizeObserver(sendEvent).observe(domElem);
}

//window.addEventListener('resize', updateCarouselList);

const cssUpdateOnScroll = (domElem) => {
  const scrollRightDistance = domElem.scrollWidth-domElem.offsetWidth-domElem.scrollLeft;
  if (scrollRightDistance <= 3){
    domElem.classList.remove('scrollRightVisible');
  } else {
    domElem.classList.add('scrollRightVisible');
  }

  if (domElem.scrollLeft <= 3){
    domElem.classList.remove('scrollLeftVisible');
  } else {
    domElem.classList.add('scrollLeftVisible');
  }
}

export default class {
  constructor(parentNode, isRecordingPermitted) {
    this.parentNode = parentNode;
    this.partnerId = parentNode.dataset.partnerId;
    this.topicId = parentNode.dataset.topicId;
    this.threadId = Number.parseInt(parentNode.dataset.threadId);
    this.isRecordingPermitted = isRecordingPermitted;
  }

  init() {
    if (!isNaN(this.threadId)) {
      return this.injectLoadingScreen()
        .then(_ => this.injectVideoTemplates())
    } else if(this.topicId && this.partnerId) {
      return this.fetchThreadId()
        .then(_ => this.injectLoadingScreen())
        .then(_ => this.injectVideoTemplates())
        .catch(_ => console.log(`Vialog: No thread is connected to the topic (topicId: ${this.topicId})`));

    } else {
      throw new Error(`Vialog: data-thread-id or both data-topic-id and data-partner-id must be set`);
    }
  }

  /*createModalBackground(){
    const domElem = document.createElement('div');
    domElem.classList.add('modalBackground');
    return domElem;
  }*/

  fetchThreadId() {
    return fetch(`${MOD_API_BASE}/thread?partnerId=${encodeURIComponent(this.partnerId)}&topicId=${encodeURIComponent(this.topicId)}`)
      .then(res => {
        if (res.status !== 200) throw new Error(res.status);
        return res;
      })
      .then(res => res.json())
      .then(res => this.threadId = res.pop().id)
  }

  removeTitleFromAll(domElem) {
    domElem.removeAttribute('title');
    if (domElem.children) {
      for (let ind = 0; ind < domElem.children.length; ind++) {
        this.removeTitleFromAll(domElem.children[ind]);
      }
    }
  }

  injectLoadingScreen() {
    const {
      replyCard  = 'Share knowledge and find answers!',
      action = 'Reply',
    } = this.parentNode.dataset;
    return fetchTemplate(`/loader?threadId=${encodeURIComponent(this.threadId)}&replyCard=${encodeURIComponent(replyCard)}&action=${encodeURIComponent(action)}`)
    .then(content => {
      const title = this.parentNode.dataset.title;
      this.setPluginColors(this.parentNode.dataset);
      this.parentNode.insertAdjacentHTML('afterbegin', content);
      this.parentNode.querySelector('h1').textContent = title;
    })
    .then(_ => {
      const domElem = this.parentNode.querySelector('[class="vialog-carousel"]');
      cssUpdateOnScroll(domElem);
      try{
        registerResizeObserver(domElem);
      } catch(e){
        console.log("Known error: ",e);
      }
    })
    .catch(error => console.log('Vialog: Cannot inject loader into the dom: ', error));
  }

  handleThemeUpdate(key, value) {
    const root = document.querySelector(':root');
    root.style.setProperty(key, value);
  }

  setPluginColors(dataset) {
    var actionButtonColor = dataset.actionButtonColor;
    if (actionButtonColor && actionButtonColor != '') {
      this.handleThemeUpdate('--vialog-pink', '#' + actionButtonColor);
    }

    var ctaColor = dataset.ctaColor;
    if (ctaColor && ctaColor != '') {
      this.handleThemeUpdate('--vialog-orange', '#' + ctaColor);
    }

    var titleColor = dataset.titleColor;
    if (titleColor && titleColor != '') {
      this.handleThemeUpdate('--vialog-grey', '#' + titleColor);
    }
  }

  inIframe () {
    try {
        return window.self !== window.top;
    } catch (e) {
      console.log(e);
        return true;
    }
  }

  injectVideoTemplates() {
    const {
      answerLabel = 'answer',
      questionLabel = 'Question',
      replyCard  = 'Share knowledge and find answers!',
      action = 'Reply',
    } = this.parentNode.dataset;
    var url = document.referrer;
    if (!this.inIframe()) {
      url = document.location.href;
    }

    return fetchTemplate(`/videos?url=${encodeURIComponent(url)}&threadId=${encodeURIComponent(this.threadId)}&answerLabel=${encodeURIComponent(answerLabel)}&questionLabel=${encodeURIComponent(questionLabel)}&replyCard=${encodeURIComponent(replyCard)}&action=${encodeURIComponent(action)}`)
      .then(content => {
        const title = this.parentNode.dataset.title;
        this.parentNode.innerHTML = "";
        this.parentNode.insertAdjacentHTML('afterbegin', content);
        this.parentNode.querySelector('h1').textContent = title;
        this.initVideos();
      })
      .then(_ => {
        const overlay = this.parentNode.querySelector(`.${POPUP_ELEMENT_CLASS}`);
        this.popup = new Popup(overlay, this.threadId);
        this.parentNode.querySelectorAll('[data-action="add-reply"]')
          .forEach(replyEl => {
            replyEl.addEventListener('click', _ => {
              if (lastReplayer != null) {
                lastReplayer.pause();
              }

              this.popup.setReplyTo(replyEl.dataset.id);
              this.popup.show();
            });
          });
      })
      .then(_ => {
        const domElem = this.parentNode.querySelector('[class="vialog-carousel"]');
        cssUpdateOnScroll(domElem);
        try{
          registerResizeObserver(domElem);
        } catch(e){
          console.log("Known error: ",e);
        }
        this.removeTitleFromAll(domElem);
        domElem.querySelector('[class="scrollIndicator leftScrollIndicator"]').addEventListener('click', scrollLeft);
        domElem.querySelector('[class="scrollIndicator rightScrollIndicator"]').addEventListener('click', scrollRight);
        domElem.addEventListener('scroll', (event) => {
          cssUpdateOnScroll(event.target);
        });
        return this.parentNode.dataset.isLoaded = true;
      })
      .catch(error => console.log('Vialog: Cannot inject thread into the dom: ', error));
  }

  stopOtherPlayers(player) {
    if (lastReplayer !== null && player !== lastReplayer) {
      lastReplayer.pause();
    }
    lastReplayer = player;
  }

  showArrow(playerContainer){
    playerContainer.querySelector('ba-videoplayer-playbutton').setAttribute('ba-show', true);
    playerContainer.querySelector('ba-videoplayer-playbutton').style.display = null;
    playerContainer.querySelector('ba-videoplayer-controlbar').setAttribute('ba-show', false);
    playerContainer.querySelector('ba-videoplayer-controlbar').style.display = 'none';
  }

  addObserverForCTACard() {
    var ctaCard = document.querySelector('[data-action="add-reply"]');
    var actionBTN = document.getElementById('footer-action-btn');
    var intersectionObserver = new IntersectionObserver(entries => {
      if (entries.some(entry => entry.isIntersecting)) {
          actionBTN.classList.remove('show-action-btn');     
      } else {
        var domElem = document.querySelector('.vialog-carousel');
        var isLeftScrollVisible = domElem.classList.contains('scrollLeftVisible');
        if (isLeftScrollVisible) {
          actionBTN.classList.add('show-action-btn');
        }
      }
    });

    intersectionObserver.observe(ctaCard);
  }

  initVideos() {
    this.parentNode.querySelectorAll('.ziggeo-player-container').forEach(el => {
      var intersectionObserver = new IntersectionObserver(entries => {
        if (entries.some(entry => entry.isIntersecting)) {
          if (el.innerHTML == '') {
          const player = new ZiggeoApi.V2.Player({
            element: el,
            attrs: {
              theme: "minimalist",
              themecolor: "red",
              video: el.dataset.token,
            }
          });

          const controlOverlay = el.nextElementSibling;
          player.activate();
          player.parentZiggeoContainer = el;
          player.on('playing', _ => {
            this.removeTitleFromAll(el);
            controlOverlay.classList.add('hide');
            controlOverlay.querySelector('.replay-action').classList.add('hide');
            controlOverlay.querySelector('.resume-action').classList.remove('hide');

            if(controlOverlay.querySelector('.flaged-text').classList.contains('hide')) {
              controlOverlay.querySelector('.flag-action-icon').classList.remove('hide');
              controlOverlay.querySelector('.flag-action-text').classList.add('hide');
            }

            el.querySelector('.ba-player-playbutton-button').style.display = 'none';
            this.stopOtherPlayers(player);
          });
          player.on('ended', _ => {
            lastReplayer = null;
            controlOverlay.classList.remove('hide');
            if (el.querySelector('.ba-player-playbutton-button')) {
              el.querySelector('.ba-player-playbutton-button').style.display = 'none';
            }

            this.removeTitleFromAll(el);
            controlOverlay.querySelector('.resume-action').classList.add('hide');
            controlOverlay.querySelector('.replay-action').classList.remove('hide');
            
          });
          player.on('paused', _ => {            
            this.removeTitleFromAll(el);
            controlOverlay.classList.remove('hide');            
          });

          controlOverlay.querySelector('.replay-action').addEventListener('click', function () {
            controlOverlay.classList.add('hide');
            player.play();
          });

          controlOverlay.querySelector('.resume-action').addEventListener('click', function () {
            controlOverlay.classList.add('hide');
            player.play();
          });

          controlOverlay.querySelector('.flag-action-icon').addEventListener('click', function () {
            controlOverlay.querySelector('.flag-action-icon').classList.add('hide');
            controlOverlay.querySelector('.flag-action-text').classList.remove('hide');
            controlOverlay.querySelector('.flag-action-text-container').classList.add('slide-in');
          });

          controlOverlay.querySelector('.flag-action-text').addEventListener('click', function () {
          fetch(`${MOD_API_BASE}/fb/user?eId=${controlOverlay.querySelector('.flag-action-text-container').dataset.id}&uft=1`, {
              method: 'POST',
              headers: {
                'Content-type': 'application/json',
              },
            }).then(_ => {
            })
            .catch(err => {
            });

            controlOverlay.querySelector('.flag-action-text').classList.add('hide');
            controlOverlay.querySelector('.flaged-text').classList.remove('hide');
          });
       }
      }
    });
    intersectionObserver.observe(el);
      
    });

    this.addObserverForCTACard();
  }
}
