import { fetchTemplate } from '../utils';
import TCWall from '../utils/TCWall';

export const STATE_READY = 'ready';
export const STATE_PENDING = 'pending';
export const STATE_CLOSED = 'closed';
export const STATE_PREPARING = 'preparing';
export const STATE_PENDING_TC = 'pending_tc';
export const STATE_PROCESSED = 'processed';
export const STATE_SUBMITTED = 'submitted';
export const STATE_NOTIFICATION = 'notification';

const ZIGGEO_SUBMIT_BTN_SELECTOR = '[data-selector="player-submit-button"]';
const MOD_API_BASE = process.env.MOD_API_BASE;

export default class {

  constructor(threadId) {
    this.stateTemplates = document.createDocumentFragment();
    this.threadId = threadId;
    this.tcWall = new TCWall();
    this.listeners = [];
    this.notificationOpen = false;
    this.videoProcessed = false;
    this.creatorEmail = null;
  }

  getCurrentTemplate() {
    if (this.stateTemplates.hasChildNodes()) {
      const template = this.stateTemplates
        .querySelector(`[data-state="${this.state}"]`);
      return Promise.resolve(template ? template.cloneNode(true) : null);
    }

    return fetchTemplate('/recorder')
      .then(templates => {
        const temp = document.createElement('template');
        temp.innerHTML = templates;
        this.stateTemplates = temp.content;

        return this.stateTemplates
          .querySelector(`[data-state="${this.state}"]`);
      }).then(template => template ? template.cloneNode(true) : null)
  }

  setReplyTo(replyTo) {
    this.replyTo = replyTo;
  }

  reset() {
    return this.transitionTo(STATE_READY);
  }

  initState() {
    return this.getCurrentTemplate().then(template => {
      switch (this.state) {
        case STATE_READY:
          return this.initReadyState(template);
        case STATE_CLOSED:
          return this.initClosedState(template);
        case STATE_PREPARING:
          return this.initPreparingState(template);
        case STATE_PENDING_TC:
          return this.initPendingTCState(template);
        case STATE_PROCESSED:
          return this.initProcessedState(template);
        case STATE_SUBMITTED:
          return this.initSubmittedState(template);
        case STATE_NOTIFICATION:
          return this.initNotificationState(template);
      }
    });
  }

  submitVideo() {
    if (this.recorder != null) {
    this.recorder.pause();
    }

    var videoTags = [
      'submitted',
      JSON.stringify({ threadId: this.threadId }),
      process.env.NODE_ENV !== 'production' ? 'approved' : 'pending'
    ];

    if (this.creatorEmail) {
      videoTags.push(JSON.stringify({ creator: this.creatorEmail }));
    }

    if (this.replyTo) {
      videoTags.push(JSON.stringify({ replyTo: this.replyTo }));
    }

    var response = ZiggeoApi.V2.Application.getDefault().videos
      .update(this.recorder.get('video'), {
        submitted: true,
        default_image_selector: 0.0,
        tags: videoTags
      });    

    // Save new video data to Moderation dashboard
    fetch(`${MOD_API_BASE}/sync/rt/${encodeURIComponent(this.threadId)}`)
      .then(result => {
        //console.log('Success:', result);
      })
      .catch(error => {
        console.error('Error:', error);
      });

    this.transitionTo(STATE_SUBMITTED);
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }

  initReadyState(content) {
    this.recorder = new ZiggeoApi.V2.Recorder({
      element: content.querySelector('.ziggeo-recorder-container'),
      attrs: {
        timelimit: 60,
        'auto-crop': true,
        'early-rerecord': true,
        recordingwidth: 405,
        recordingheight: 720,
        localplayback: false,
        camerafacefront: true,
        theme: 'minimalist',
        themecolor: 'red',
        'video-profile': '_vialog_poff',
        countdown: 3,
        noflash: true,
        allowupload: false,
        skipinitial: true,
        autorecord: false,
        primaryrecord: true,
        skipinitialonrerecord: true,
        manualsubmit: true,
        'manual-upload': false,
        orientation: 'portrait',
        picksnapshots: false,
        tags: [
          JSON.stringify({ threadId: this.threadId }),
          process.env.NODE_ENV !== 'production' ? 'approved' : 'pending'
        ]
      }
    });

    [
      'upload_selected',
      'recording',
      'uploading',
      'processing',
      'verified',
    ].forEach(event => this.recorder.on(event, ev => console.log(event, ev)));

    this.tcWall.activateOn(content, () => this.transitionTo(STATE_PENDING_TC));
    this.recorder.on('manually_submitted', this.submitVideo.bind(this));

    this.recorder.on('uploaded', (event) => {  
        this.transitionTo(STATE_PREPARING); 
    });

    this.recorder.on('processed', (event) => {
      if (!this.notificationOpen) {
        this.showAfterProcessed();
      } else {
        this.videoProcessed = true;
      }
    });
    
    this.recorder.on('ended', () => {
      if (this.isSafari()) {
        document.querySelector(`[data-state="processed"]`).style.display = "block";
      }
    });
    this.recorder.on('playing', () => {
      if (this.isSafari()) {
        document.querySelector(`[data-state="processed"]`).style.display = "none";
      }
      else {
        this.recorder.element()[0].parentElement.querySelector('ba-videoplayer-playbutton').classList.add('notFirst');
      }
    });
    this.recorder.on('paused', () => {
      if (this.isSafari()) {
        document.querySelector(`[data-state="processed"]`).style.display = "block";
      }
    });

    this.recorder.on("rerecord", function () {
      document.querySelector('[id="recorderHeader"]').innerHTML = 'Press start when you\'re ready';
    });

    this.recorder.activate();
    this.recorder.element()[0].parentElement.querySelector('[id="recorderCloseButton"]').style.display = 'block';
    //this.recorder.record();

    // setTimeout(() => {
    //   const cameraInput = document.querySelector('.ba-videorecorder-chooser-button-0 input[ba-if="true"]');
    //   if (cameraInput) {
    //     cameraInput.setAttribute('capture', 'user');
    //     cameraInput.click();
    //   }
    //   else {
    //     setTimeout(() => {
    //       const cameraInput = document.querySelector('.ba-videorecorder-chooser-button-0 input[ba-if="true"]');
    //       if (cameraInput) {
    //         cameraInput.setAttribute('capture', 'user');
    //         cameraInput.click();
    //       }
    //     }, 50);
    //   }
    // }, 5);

    content.querySelector('[data-action="close"]')
      .addEventListener('click', _ => {
        this.transitionTo(STATE_CLOSED);
      });
    /*this.recorder.element()[0].parentElement
      .addEventListener('click', e => {
        e.stopPropagation();
      });
    content.subToCloseFunction = (domElem) => {
      const domElemClone = domElem.cloneNode(true);

      domElem.parentNode.replaceChild(domElemClone, domElem);
      domElemClone.addEventListener('click', _ => {
        this.transitionTo(STATE_CLOSED);
      });
      domElemClone.addEventListener('click', _ => {
        domElemClone.classList.remove('active');
        }
      );
    };*/
    return content;
  }

  showAfterProcessed() {
    const data = {
      default_image_selector: 0.0
    };

    this.recorder.element()[0].parentElement.querySelector('[id="recorderHeader"]').innerHTML = 'Tap Happy below to submit';   
    console.log("V2", this.recorder.get('video'), ZiggeoApi, ZiggeoApi.V2.Application.getDefault());
    console.log(ZiggeoApi.V2.Application.getDefault().videos.update(this.recorder.get('video'), data));

    if (document.querySelector(`[data-state="preparing"]`) != null) {
      document.querySelector(`[data-state="preparing"]`).remove();
    }
    
    if (this.isSafari()) {
      this.transitionTo(STATE_PROCESSED);
    } else {
      this.recorder.play();
    }
  }

  initClosedState() {
    this.recorder.destroy();
  }

  initPreparingState(content) {
    content.querySelector('[data-action="get-notifications"]')
    .addEventListener('click', () => {
      this.transitionTo(STATE_NOTIFICATION);
      content.remove();
    });

    if (this.creatorEmail) {
      content.querySelector('#get-notification-button').classList.add('hide'); 
      content.querySelector('#preparing-subP').classList.remove('hide');
    } else {
      content.querySelector('#get-notification-button').classList.remove('hide');
      content.querySelector('#preparing-subP').classList.add('hide');
    }

    this.recorder.element()[0].parentElement.querySelector('[id="recorderHeader"]').innerHTML = 'Please wait...';
    return content;
  }

  initProcessedState(content) {    
    document.getElementsByClassName('ba-player-rerecord-frontbar')[0].setAttribute("style", "display: none;");
    content.querySelector('[data-action="start-replay"]')
    .addEventListener('click', _ => {
      this.recorder.play();   
      document.querySelector(`[data-state="processed"]`).style.display = "none";
    });
     content.querySelector('[data-action="submit"]')
    .addEventListener('click', _ => {
      this.submitVideo();
      content.remove();
    }); 
    content.querySelector('[data-action="retry"]')
    .addEventListener('click', _ => {
      this.recorder.reset();
      content.remove();
    });

    this.recorder.element()[0].parentElement.querySelector('[id="recorderHeader"]').innerHTML = 'Tap Happy below to submit';
    return content;
  }

  initPendingTCState(content) {
    if (this.recorder != null) {
      this.recorder.pause();
    }

    content.querySelector('[data-action="agree-tc"]')
      .addEventListener('click', () => {
        localStorage.setItem('is_vialog_tc_accepted', 1);
        /* document.querySelector(ZIGGEO_SUBMIT_BTN_SELECTOR).dispatchEvent(
          new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
          })); */
        this.submitVideo();
        content.remove();
      });
    content.querySelector('[data-action="cancel-tc"]')
      .addEventListener('click', () => {
        document.querySelector('[id="recorderHeader"]').innerHTML = 'Press start when you\'re ready';
        document.querySelector('[id="recorderFooter"]').innerHTML = 'You can redo your video and replay it before you submit';
        this.recorder.element()[0].parentElement.querySelector('[id="recorderCloseButton"]').style.display = 'block';
        content.remove()
      });
    this.recorder.element()[0].parentElement.querySelector('[id="recorderCloseButton"]').style.display = 'none';
    this.recorder.element()[0].parentElement.querySelector('[id="recorderHeader"]').innerHTML = 'One last thing';
    return content;
  }

  initSubmittedState(content) {
    content.querySelector('[data-action="close-recorder"]')
      .addEventListener('click', () => {
        this.transitionTo(STATE_CLOSED);
        content.remove();
      });

      if (this.creatorEmail) {
        content.querySelector('#submitted-subP').classList.remove('hide');
      } else {
        content.querySelector('#submitted-subP').classList.add('hide');
      }

      this.recorder.element()[0].parentElement.querySelector('[id="recorderCloseButton"]').style.display = 'block';
      this.recorder.element()[0].parentElement.querySelector('[id="recorderHeader"]').innerHTML = '';
      this.recorder.element()[0].parentElement.querySelector('[id="recorderFooter"]').innerHTML = '<div>Thanks for your contribution.</div>';
    return content;
  }

  initNotificationState(content) {
    this.notificationOpen = true;
    if (this.creatorEmail) {
      document.getElementById('notification-email').value = this.creatorEmail;
    }

    // Close Button
    content.querySelector('[data-action="close-notification"]')
      .addEventListener('click', () => {
        if (this.videoProcessed) {
          this.showAfterProcessed();
        } else {
          this.transitionTo(STATE_PREPARING);
        }

        this.notificationOpen = false;
        content.remove();
      });

      // Get Reply Button
      content.querySelector('[data-action="get-notifications"]')
      .addEventListener('click', () => {
        this.creatorEmail = document.getElementById('notification-email').value;
        if (!this.validateEmail(this.creatorEmail)) {
          document.getElementById('notification-email').classList.add('error');
          this.creatorEmail = '';
        } else {
          document.getElementById('notification-email').classList.remove('error');
        
          if (this.videoProcessed) {
            this.showAfterProcessed();
          } else {
            this.transitionTo(STATE_PREPARING);
          }

          this.notificationOpen = false;
          content.remove();
        }
      });

      this.recorder.element()[0].parentElement.querySelector('[id="recorderHeader"]').innerHTML = 'Do you want email updates?';
    return content;
  }

  transitionTo(state) {
    this.state = state;
    return this.initState()
      .then(content => this.dispatchChange({ state, content }))
  }

  subscribe(callback) {
    this.listeners.push(callback);
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  dispatchChange(eventObject) {
    this.listeners.forEach(listener => listener(eventObject));
  }
}

/*  window.onerror = function(msg, url, linenumber) {
  alert('Error message: '+msg+'\nURL: '+url+'\nLine Number: '+linenumber);
  return true; 
} */
