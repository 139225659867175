const TEMPLATES_BASE = process.env.PLUGIN_API_BASE;

export function fetchTemplate(url) {
  return fetch(TEMPLATES_BASE.concat(url))
    .then(res => {
      if (res.status !== 200) throw new Error();
      return res;
    })
    .then(res => res.text());
};
